/**
 * Global
 *
 * Contains some global styling.
 *
 */

@import "styles/typography";
@import "styles/spacings";

*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
	font-size: $font-size-base;
}

body {
	font-family: $font-family-base;
	margin: 0;
	font-size: $font-size-standard;
}

#main-content {
	margin-bottom: $spacing-7x;
}

.is-visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;

	&.focusable:active, &.focusable:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
	}
}