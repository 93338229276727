/**
  * Pager
  *
  * Styling for the Pager.
  *
**/

@import "styles/colors";
@import "styles/typography";
@import "styles/spacings";

.pagination {
	@include font-size($p-sizes);

	&__list {
		list-style: none;
		display: inline-block;
		padding: 0;
		margin: $spacing-1x 0 0 0;
	}

	&__item {
		display: inline-block;

		&--active {
			pointer-events: none;

			a {
				background-color: color(orange-40);
				font-weight: $font-weight-bold;
				pointer-events: none;
			}
		}

		&--empty {
			height: 4.2rem;
			width: 4.2rem;
			display: inline-flex;
			justify-content: center;
			align-items: center;
		}

		&:hover a {
			text-decoration: underline;
		}

		a {
			height: 4.2rem;
			width: 4.2rem;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			color: color(blue-dark);
			text-decoration: none;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
