/**
  * UsersPage
  *
  * Styling for the UsersPage.
  *
  *
**/

@import "styles/colors";
@import "styles/spacings";
@import "styles/typography";
@import "styles/media-queries";

.userspage {
	&__heading {
		@extend %text-h2;
		color: $text-color;  
	}

	&__subheading {
		@include font-size($p-sizes-small);
		color: $text-color;
		margin-bottom: 0;
		font-weight: $font-weight-bold;
	}

	&__section {
		padding: $spacing-4x 0;

		&--top {
			border-top: 0.1rem solid color(grey-20);
			border-bottom: 0.1rem solid color(grey-20);
		}
	}

	&__button {
		display: flex;
		align-items: center;

		.icon {
			font-size: 2.5rem;
			margin-right: $spacing-1x; 
		}
	}

	&__input {
		width: 100%;
		max-width: 60rem;
		
		input {
			margin-bottom: 0;
		}
	}

	&__flex {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		:first-child {
			flex-grow: 1;
		}
	}

	&__submit {
		display: inline-block;
		margin-left: $spacing-2x;
	}

	&__error {
		color: color(red);
		@include font-size($p-sizes-small);
		margin-left: $spacing-2x;
	}

	@media (max-width: $device-small-max) {
		&__input {
			width: 100%;
			margin-bottom: $spacing-2x;
		}
	}
}