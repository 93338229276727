/**
  * StartPage
  *
  * Styling for the StartPage.
  *
  *
**/

@import "styles/spacings";
@import "styles/typography";
@import "styles/colors";
@import "styles/media-queries";

.loginpage {
	&__heading {
		@extend %text-h1;
		color: $text-color;
		text-align: center;
		margin: $spacing-8x 0;
	}

	&__form {
		margin-top: $spacing-6x;
	}

	&__input {
		margin: $spacing-2x auto;
		width: 50%;

		@media (max-width: $device-small-max) {
			width: 100%;
		}
	}

	&__button {
		margin-top: $spacing-3x !important;
	}

	&__error {
		margin: 0 auto;
		width: 90%;
		@include font-size($p-sizes-small);
	}
}
