/**
  * ReportsPage
  *
  * Styling for the ReportsPage.
  *
  *
**/

@import 'styles/colors';
@import 'styles/spacings';
@import 'styles/typography';

.reportspage {
	&__heading {
		@extend %text-h2;
		color: $text-color;
	}

	&__subheading {
		@include font-size($p-sizes-small);
		color: $text-color;
		margin-bottom: 0;
	}

	&__section {
		padding: $spacing-4x 0;

		&--top {
			border-top: 0.1rem solid color(grey-20);
			border-bottom: 0.1rem solid color(grey-20);
		}
	}

	&__input {
		display: inline-block;
		width: 50%;
		margin-right: $spacing-2x;
	}

	&__buttons {
		margin-top: $spacing-3x;
		display: flex;
		flex-wrap: wrap;

		:first-child {
			flex-grow: 1;
		}

		:last-child {
			margin-right: 0;
		}

		button {
			margin-right: $spacing-2x;
		}
	}

	&__pagination {
		display: flex;
		align-items: center;
	}

	&__counter {
		@extend %text-paragraph;
		color: color(grey-75);
		margin-bottom: 1.3rem;
		margin-left: $spacing-4x;
	}
}

.smalltext {
	font-size: 12px;
}
