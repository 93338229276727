/**
  * PageFooter
  *
  * Styling for the PageFooter.
  *
  *
**/

@import "styles/colors";

.page-footer {
	background-color: color(blue);
	height: 3rem;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
}