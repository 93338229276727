/**
 * Button
 *
 * Styling for icons.
 *
 */

@import 'styles/colors';
@import 'styles/typography';

.button {
	@extend %typography-base;
	@include font-size($p-sizes);

	vertical-align: middle;
	text-align: center;
	padding: 1rem 3.1rem 1rem;
	margin-bottom: 0;
	display: inline-block;
	background-color: color(white);
	border-radius: 3rem;
	text-transform: uppercase;
	cursor: pointer;
	color: color(grey);
	text-decoration: none;
	transition: background-color 0.4s ease-out;

	&--blue {
		background-color: color(blue-dark);
		color: color(white);

		&:hover {
			background-color: darken(color(blue-dark), 10%);
		}
	}

	&--grey {
		&:hover {
			background-color: color(grey-5);
		}
	}
}
