/**
  * Filters
  *
  * Styling for the filters.
  *
  *
**/

@import "styles/spacings";
@import "styles/colors";

.filters {
	&__time {
		display: flex;
		border-top: 0.1rem solid color(grey-40);

		.form__item {
			justify-content: space-between;
			padding-right: $spacing-1x;
		}
	}
}
