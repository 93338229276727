@charset "UTF-8";
/**
 * Icon
 *
 * Styling for icons.
 *
 */
/**
 * Table of Contents:
 *
 * 1.0 - Icons variables
 * 2.0 - Icons style
 * 3.0 - Icons colors
 * 4.0 - Exports 
 * -----------------------------------------------------------------------------
 */
/**
 * Colors
 *
 * Contains theme colors
 *
 */
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * - 1.1 Color usage
 * 2.0 - Functions
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.1 Color usage
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Functions
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 - Icons variables
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Icons style
 * -----------------------------------------------------------------------------
 */
@font-face {
  font-family: 'icomoon';
  src: url("/assets/fonts/icomoon/icomoon.eot?3nzf8q");
  src: url("/assets/fonts/icomoon/icomoon.eot?3nzf8q#iefix") format("embedded-opentype"), url("/assets/fonts/icomoon/icomoon.ttf?3nzf8q") format("truetype"), url("/assets/fonts/icomoon/icomoon.woff?3nzf8q") format("woff"), url("/assets/fonts/icomoon/icomoon.svg?3nzf8q#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-chevron-down:before {
  content: ""; }

.icon-chevron-left:before {
  content: ""; }

.icon-chevron-right:before {
  content: ""; }

.icon-chevron-up:before {
  content: ""; }

.icon-chevrons-left:before {
  content: ""; }

.icon-chevrons-right:before {
  content: ""; }

.icon-home:before {
  content: ""; }

.icon-layers:before {
  content: ""; }

.icon-log-out:before {
  content: ""; }

.icon-x:before {
  content: ""; }

.icon-plus:before {
  content: ""; }

.icon-sort:before {
  content: ""; }

.icon-info:before {
  content: ""; }

.icon-sort-desc:before {
  content: ""; }

.icon-sort-asc:before {
  content: ""; }

.icon-spinner:before {
  content: ""; }

.icon-play-circle:before {
  content: ""; }

/**
 * 3.0 - Icons colors
 * -----------------------------------------------------------------------------
 */
.icon--white {
  color: #fff; }

.icon--grey {
  color: #7A7A7A; }

/**
 * 4.0 - Exports
 * -----------------------------------------------------------------------------
 */
:export {
  icons: arrow-left, arrow-right, chevron-down, chevron-left, chevron-right, chevron-up, chevrons-left, chevrons-right, home, layers, log-out, x, plus, sort, info, sort-desc, sort-asc, spinner, play-circle; }
