/**
 * Layout
 *
 * Styling for the Layout.
 *
 */

@import "styles/colors";
@import "styles/spacings";
@import "styles/media-queries";

.layout {
	overflow: hidden;
	display: block;

	@media (max-width: $device-small-max) {
		height: 100%;
	}

	&__content {
		margin: 0 auto;
		max-width: $device-large-max;

		@media (max-width: $device-large-max) {
			margin: 0 $spacing-4x;
		}

		@media (max-width: $device-small-max) {
			margin: 0 $spacing-3x;
			height: 100%;
		}
	}

	&--white {
		background-color: color(white);
	}

	&--grey-light {
		background-color: color(blue-lightest);
	}
}
