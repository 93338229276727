/**
  * ErrorPage
  *
  * Styling for the ErrorPage.
  *
  *
**/

@import "styles/spacings";
@import "styles/typography";
@import "styles/colors";

.errorpage {
	text-align: center;
	margin-top: $spacing-4x;

	&__heading {
		@extend %text-h1;
		margin-top: $spacing-5x;
		color: $text-color;
	}

	&__text {
		margin-top: $spacing-3x;
		color: $text-color;
		@extend %text-paragraph;

		a {
			@extend %text-link;
			color: $link-color;
			text-decoration: underline;
		}
	}
}
