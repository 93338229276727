/**
  * PageHeader
  *
  * Styling for the PageHeader.
  *
  *
**/

@import "styles/colors";
@import "styles/spacings";
@import "styles/typography";
@import "styles/media-queries";

.page-header {
	padding-top: $spacing-3x;
	color: color(white);
	overflow: hidden;

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2c71c3+0,2a4789+100 */
	background: rgb(44,113,195); /* Old browsers */
	background: -moz-linear-gradient(-45deg, rgba(44,113,195,1) 0%, rgba(42,71,137,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, rgba(44,113,195,1) 0%,rgba(42,71,137,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, rgba(44,113,195,1) 0%,rgba(42,71,137,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c71c3', endColorstr='#2a4789',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

	.layout {
		overflow: visible;
	}

	&__content {
		display: flex;
		align-items: flex-end;
		position: relative;
	}

	&__skip {
		@extend %text-link;
		color: color(white);
		padding: 0 $spacing-2x;
	}

	&__button {
		z-index: 1;
		position: absolute;
		top: 0;
		right: 0;

		a {
			display: flex;
			align-items: center;
			color: color(white);
			@extend %text-link;
			text-transform: uppercase;
			font-size: 1.5rem;

			&:hover {
				text-decoration: none;
	
				.page-header__button__text {
					text-decoration: underline;
				}
			}

			.icon {
				margin-left: $spacing-2x;
				font-size: 3rem;
			}
		}
	}

	&__logo {
		margin: 0 $spacing-6x $spacing-2x $spacing-4x;

		img {
			width: 12rem;
			height: 12rem;
			-webkit-box-shadow: 0px 0px 16px 3px rgba(0,0,0,0.28);
			-moz-box-shadow: 0px 0px 16px 3px rgba(0,0,0,0.28);
			box-shadow: 0px 0px 16px 3px rgba(0,0,0,0.28);
			border-radius: 50%;
		}
	}

	@media (max-width: $device-small-max) {
		margin-top: 0;

		&__content {
			flex-direction: column;
			align-items: center;
		}

		&__logo {
			margin-top: $spacing-3x;

			img {
				width: 10rem;
				height: 10rem;
			}
		}

		.brand-header {
			display: none;
		}		
	}
}
