/**
  * Modal
  *
  * Styling for the Modal.
  *
  *
**/

@import "styles/colors";
@import "styles/spacings";
@import "styles/typography";
@import "styles/media-queries";

.modal {
	background-color: color(white);
	padding: $spacing-5x;
	border-radius: 3rem;
	width: 72rem;
	position: relative;
	margin: $spacing-8x 0;

	&__heading {
		@extend %text-h2;
		color: $text-color;  
	}

	&__close {
		position: absolute;
		top: -4rem;
		right: -3rem;
		font-size: 4rem;
		text-decoration: none;
	}

	@media (max-width: $device-small-max) {
		width: 100%;

		&__close {
			right: -1rem;
			top: -5rem;
		}
	}
}