/**
 * Spacing
 *
 * Contains defined spacings
 *
 */

$spacing-05x: 			0.4rem;
$spacing-1x: 			0.8rem;
$spacing-2x: 			1.6rem;
$spacing-3x: 			2.4rem;
$spacing-4x: 			3.2rem;
$spacing-5x: 			4rem;
$spacing-6x: 			4.8rem;
$spacing-7x: 			5.6rem;
$spacing-8x: 			6.4rem;
$spacing-9x: 			7.2rem;
$spacing-10x:			8rem;
