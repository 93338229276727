/**
 * Typography
 *
 * Contains typography settings
 *
 */

/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * - 2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */

@import "media-queries";
@import "colors";

/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */

// Font family
$font-family-base:				poppins, sans-serif;
$font-family-alt:				poppins, sans-serif;


// Font weights
$font-weight-normal: 			400;
$font-weight-bold: 				600;
$font-weight-extra-bold: 		800;


// Breakpoints
$typography-breakpoints: (
	small: $device-small-min,
	medium: $device-medium-min,
	large: $device-large-min
);

// Base font-size
$font-size-base:				62.5%; // 10px
$font-size-standard:			2rem; // 20px, this is the font-size every element will get that has no font-size specified in the css.


// Base typography
$line-height-standard:			2.6rem;
$line-height-standard-pixel:	26px;

// Header font-sizes
// Example: small: (font-size, line-height, margin-bottom, padding-top) (We use padding-top beacues if we used margins they would collapse into each other.)
$h1-sizes: (
	small: (2.6rem, 3rem, 1rem),
	medium: (2.6rem, 3rem, 1rem),
	large: (3.2rem, 3.4rem, 1rem),
);
$h2-sizes: (
	small: (2rem, 2.6rem, 1.2rem),
	medium: (2.2rem, 2.8rem, 1.6rem)
);
$h3-sizes: (
	small: (2rem, 2.6rem, 1.2rem),
	medium: (2rem, 2.6rem, 1.6rem)
);
$h4-sizes: (
	small: (2.2rem, 2.6rem, 1.2rem),
	medium: (2.4rem, 3.2rem, 1.6rem)
);

// Paragraph font-sizes
$p-sizes: (
	null: (1.6rem, 2.4rem, 2rem)
);
$p-sizes-small: (
	null: (1.4rem, 2.4rem, 2rem)
);

$label-sizes: (
	null: (1.4rem, 2rem)
);

/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
 */

/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */

@mixin font-size($fs-map, $fs-breakpoints: $typography-breakpoints) {
	@each $fs-breakpoint, $fs-font-size in $fs-map {
		@if $fs-breakpoint == null {
			@include make-font-size($fs-font-size);
		}
		@else {
			// If $fs-font-size is a key that exists in
			// $fs-breakpoints, use the value
			@if map-has-key($fs-breakpoints, $fs-breakpoint) {
				$fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
			}
			@media all and (min-width: $fs-breakpoint) {
				@include make-font-size($fs-font-size);
			}
		}
	}
}


/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */

@mixin make-font-size($fs-font-size) {
	// If $fs-font-size is a list, include
	// both font-size and line-height
	@if type-of($fs-font-size) == "list" {
		font-size: nth($fs-font-size, 1);
		margin-top: 0;

		@if (length($fs-font-size) > 1) {
			line-height: nth($fs-font-size, 2);
		}
		@if (length($fs-font-size) > 2) {
			margin-bottom: nth($fs-font-size, 3);
		}
		@if (length($fs-font-size) > 3) {
			padding-top: nth($fs-font-size, 4);
		}
	}
	@else {
		font-size: $fs-font-size;
	}
}


/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */

%typography-base {
	color: $text-color;
	font-family: $font-family-base;
	line-height: $line-height-standard;
}

%typography-base-headings {
	font-family: $font-family-alt;
	font-weight: $font-weight-normal;
	font-style: normal;
}

%text-h1 {
	@extend %typography-base-headings;
	@include font-size($h1-sizes);
}

%text-h2 {
	@extend %typography-base-headings;
	@include font-size($h2-sizes);
}

%text-h3 {
	@extend %typography-base-headings;
	@include font-size($h3-sizes);
}

%text-h4 {
	@extend %typography-base-headings;
	@include font-size($h4-sizes);
}

%text-paragraph {
	@include font-size($p-sizes);
}

%text-link {
	color: $link-color;
	text-decoration: none;

	&:hover {
		color: shade($link-color, 10%);
		text-decoration: underline;
	}
	&:active {
		color: shade($link-color, 20%);
		text-decoration: underline;
	}
}
