/**
  * MainMenu
  *
  * Styling for the MainMenu.
  *
  *
**/

@import "styles/colors";
@import "styles/spacings";
@import "styles/typography";
@import "styles/media-queries";

.mainmenu {
	flex-grow: 1;
	z-index: 99;
	display: flex;
	flex-direction: row-reverse;

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;

		&__item {
			display: inline-block;
			margin-right: $spacing-1x; 
			
			a {
				display: block;
				text-decoration: none;
				color: color(white);
				transition: 0.4s ease-out;

				background-color: color(blue-darker);
				padding: $spacing-1x $spacing-2x;
				border-top-left-radius: 1.5rem;
				border-top-right-radius: 1.5rem;

				&:hover, &.active {
					color: color(blue-dark);
					background-color: color(white);
				}

				@media (max-width: 1120px) {
					@include font-size($p-sizes);
					margin: 0;
				}
			}
		}
	}

	@media (max-width: $device-small-max) {
		width: 100%;
		margin-bottom: $spacing-2x;

		&__list {
			width: 100%;

			&__item {
				width: 100%;
				display: block;
				margin: $spacing-1x 0;

				a {
					border-radius: 1.5rem;
					width: 100%;
					text-align: center;
				}
			}
		}
	}
}
